import URL from '../constants/URL';
import axios from 'axios';

export default class Requester {

    static getMatches(userId) {
        return _get(URL.matches(userId));
    }

    static getMatch(matchId) {
        return _get(URL.match(matchId));
    }
}

const _get = async (url) => {
    return new Promise((resolve, reject) => {
        axios.get(url, {})
            .then((response) => {
                resolve(response.data);
            })
            .catch(function (error) {
                console.error(error);
                reject(error.response)
            });
    });
}
