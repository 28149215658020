import React from 'react';

function NotFound() {

    return (
        <div>
            Not found!
        </div>
    );
}

export default NotFound;
