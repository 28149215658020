import React, {useEffect} from 'react';
import '../../App.css';
import {useParams} from "react-router-dom";
import {DataGrid} from '@mui/x-data-grid';
import Requester from "../../service/Requester";
import Utils from "../../service/Utils";
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import {Button, Checkbox, Grid, ListItemText, MenuItem, OutlinedInput, Select} from "@mui/material";
import LoadingScreen from "../components/Loading";

const Matches = () => {

    const initialColumns = [
        {field: 'date', headerName: 'Date', width: 150},
        {field: 'duration', headerName: 'Duration', width: 100},
        {field: 'status1', headerName: 'Game status', width: 100},
        {field: 'status2', headerName: 'Match status', width: 120},
        {field: 'status3', headerName: 'Registration status', width: 120},
        {field: 'location', headerName: 'Location', width: 200},
        {field: 'type', headerName: 'Type', width: 100},
        {field: 'visibility', headerName: 'Visibility', width: 100},
        {field: 'players', headerName: 'Players', width: 100}
    ];

    const params = useParams();
    const [loadedMatches, setLoadedMatches] = React.useState(false);
    const [isUserFavourite, setUserFavourite] = React.useState(false);
    const [matches, setMatches] = React.useState([]);
    const [player, setPlayer] = React.useState("");
    const [columns, setColumns] = React.useState(initialColumns);

    let userId = params.id;

    useEffect(() => {
        Requester.getMatches(userId).then(matches => {
            setMatches(matches);
            setLoadedMatches(true);
        });
        let users = window.localStorage.getItem("users");
        if (users) setPlayer(JSON.parse(users).filter(user => user.id === userId)[0].name);

        let stringCols = window.localStorage.getItem("matches-table-columns");
        if (stringCols) setColumns(JSON.parse(stringCols));

        let favUsers = JSON.parse(window.localStorage.getItem("fav-users") || "[]");
        if (favUsers.includes(userId)) setUserFavourite(true);
    }, []);

    const rows = matches.map(match => ({
        id: match.match_id,
        date: Utils.parseDateUTC(match.start_date),
        duration: Utils.getDuration_minutes(match.start_date, match.end_date),
        status1: match.game_status,
        status2: match.status,
        status3: match.registration_status,
        location: match.location,
        type: match.match_type,
        visibility: match.visibility,
        players: match.teams.flatMap(team => team.players.map(player => player.name)).length + "/" + match.teams.map(team => team.min_players).reduce((a, b) => a + b)
    }));

    const handleFavButton = () => {
        setUserFavourite(!isUserFavourite);

        let favUsers = JSON.parse(window.localStorage.getItem("fav-users") || "[]");
        if (favUsers.includes(userId)) {
            let i = favUsers.indexOf(userId);
            favUsers.splice(i, 1);
        } else favUsers.push(userId);
        window.localStorage.setItem("fav-users", JSON.stringify(favUsers))
    }

    const selectColumns = (event) => {
        const {
            target: {value},
        } = event;

        let newColumns = initialColumns.filter(c => value.includes(c.headerName));
        setColumns(newColumns);
        window.localStorage.setItem("matches-table-columns", JSON.stringify(newColumns))
    };

    return (
        <div>
            <h2>{player ? player + " matches" : ""}{favElement()}</h2>
            {!loadedMatches ?
                <LoadingScreen/> :
                <Grid container spacing={2}>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={9} md={8} xl={9}>
                            {selectElement()}
                        </Grid>
                        <Grid item xs={3} md={4} xl={3}>
                            {favButtonElement()}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {tableElement()}
                    </Grid>
                </Grid>
            }
        </div>
    );

    function favElement() {
        let starStyle = {
            fontSize: 48,
            cursor: "pointer",
            position: "relative",
            top: 7,
            left: 15
        };
        if (isUserFavourite) return <StarRoundedIcon style={starStyle} onClick={handleFavButton}/>;
        else return <StarOutlineRoundedIcon style={starStyle} onClick={handleFavButton}/>;
    }

    function favButtonElement() {
        let text = isUserFavourite ? "Remove from favourites" : "Add to favourites";
        return (
            <Button fullWidth={true}
                    variant={"outlined"}
                    style={{height: "100%", maxHeight: "100%", fontSize: 12}}
                    onClick={handleFavButton}
            >
                {text}
            </Button>);
    }

    function tableElement() {
        return (
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={15}
                rowsPerPageOptions={[5]}
                disableSelectionOnClick
                disable
                loading={!loadedMatches}
                autoHeight={true}
                style={{color: "white"}}
            />
        );
    }

    function selectElement() {
        return (<Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={columns.map(c => c.headerName)}
                onChange={selectColumns}
                input={<OutlinedInput label="Tag"/>}
                renderValue={(selected) => selected.join(', ')}
                style={{color: "white", width: "100%"}}
            >
                {initialColumns.map((column) => (
                    <MenuItem key={column.field} value={column.headerName}>
                        <Checkbox
                            checked={columns.filter(c => c.headerName === column.headerName).length > 0}/>
                        <ListItemText primary={column.headerName}/>
                    </MenuItem>
                ))}
            </Select>
        );
    }


}

export default Matches;
