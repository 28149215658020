import React from 'react';
import {CircularProgress, Typography} from '@mui/material';

export default function LoadingScreen({text, loading}) {
    if (loading === false) return "";
    return (
        <div align={'center'}>
            <CircularProgress color="secondary"/>
            {text && <Typography>{text}</Typography>}
        </div>
    );
}
