import Requester from "./Requester";

export default class Init {

    constructor(userId) {
        this.userId = userId;
    }

    async initUsers(depth) {
        let users = await loadUsers(depth, {[this.userId]: null})
        if (users[this.userId] === null) return [];
        let usersArray = Object.entries(users).map(entry => ({id: entry[0], name: entry[1]}));
        saveUsers(usersArray);
        return usersArray;
    }
}

const loadUsers = async (depth, users) => {
    if (depth === 0) return users;
    for (let userId of Object.keys(users)) {
        let matches = await Requester.getMatches(userId);
        let newUsers = extractUsersFromMatches(matches);
        users = {...users, ...newUsers}
    }
    return loadUsers(depth - 1, users);
}

const saveUsers = (users) => {
    window.localStorage.setItem("users", JSON.stringify(users));
}

const extractUsersFromMatches = (matches) => {
    let users = {};
    matches.forEach(
        match => match.teams.forEach(
            team => team.players.forEach(
                player => {
                    users[player.user_id] = player.name;
                }
            )
        )
    );
    return users;
}
