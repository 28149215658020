export default class URL {

    static matches(userId) {
        return `https://playtomic.io/api/v1/matches?user_id=${userId}`;
    }

    static match(matchId) {
        return `https://playtomic.io/api/v1/matches/${matchId}`;
    }
}
