import React, {useEffect} from 'react';
import '../../App.css';
import {InitialUser} from "../../constants/Constants";
import {Button, Grid, TextField} from "@mui/material";
import {Link} from "react-router-dom";

const User = () => {

    const [user, setUser] = React.useState("");

    useEffect(() => {
        if (user === "jacobin") setUser(InitialUser.id);
    }, [user]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField fullWidth={true} onChange={(e) => setUser(e.target.value)}/>
            </Grid>
            <Grid item xs={4}>
            </Grid>
            <Grid item xs={12}>
                <Button fullWidth={true} variant={"outlined"} component={Link}
                        to={`/users/${user}`}>Search user</Button>
            </Grid>
        </Grid>
    );
}

export default User;
