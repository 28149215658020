import React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import User from "./components/screens/User";
import NotFound from "./components/screens/NotFound";
import Matches from "./components/screens/Matches";
import Navbar from "./components/components/Navbar";
import Home from "./components/screens/Home";

function App() {
    return (
        <BrowserRouter>
            <div className={"App"}>
                <Navbar/>
                <div className={"content"}>
                    <Routes>
                        <Route index element={<Home/>}/>
                        <Route path={"/users/:id"} index element={<User/>}/>
                        <Route path={"/users/:id/matches"} element={<Matches/>}/>
                        {/*<Route path="about" element={<About/>}/>*/}
                        {/*<Route path="dashboard" element={<Dashboard/>}/>*/}
                        <Route path="*" element={<NotFound/>}/>
                    </Routes>
                </div>
            </div>
        </BrowserRouter>
    );
}

export default App;
