export default class Utils {

    static parseDateUTC = (string) => {
        return Utils.parseDate(string + "Z");
    }

    static parseDate = (string) => {
        try {
            let date = new Date(string);
            return date.getFullYear()
                + "-"
                + addLeftZeros(date.getMonth() + 1, 2)
                + "-"
                + addLeftZeros(date.getDate(), 2)
                + " "
                + addLeftZeros(date.getHours(), 2)
                + ":"
                + addLeftZeros(date.getMinutes(), 2);
        } catch (e) {
            console.warn(e);
            return "";
        }
    }

    static getDuration_minutes = (from, to) => {
        let fromDateVar = new Date(from);
        let toDateVar = new Date(to);
        let duration = toDateVar - fromDateVar;
        return (duration/1000)/60 + " min";
    }
}

const addLeftZeros = (input, maxLength) => {
    let string = input.toString();
    while ((maxLength - string.length) > 0) {
        string = "0" + string;
    }
    return string;
}
