import React, {useEffect} from 'react';
import '../../App.css';
import Init from "../../service/Init";
import {Autocomplete, Button, Grid, TextField} from "@mui/material";
import {Link, useParams} from "react-router-dom";
import NotFound from "./NotFound";
import LoadingScreen from "../components/Loading";

const User = () => {

    const params = useParams();
    let userId = params.id;

    const init = new Init(userId);

    const [knownUsers, setKnownUsers] = React.useState(null);
    const [selectedUser, selectUser] = React.useState(userId);
    const [player, setPlayer] = React.useState("");

    useEffect(() => {
        init.initUsers(1).then(
            users => setKnownUsers(users))
    }, []);

    useEffect(() => {
        if (!knownUsers) return;
        if (knownUsers.length > 0) setPlayer(knownUsers.filter(user => user.id === userId)[0].name);
        else setPlayer(null);
    }, [knownUsers]);

    if (player === null) return <NotFound/>;
    if (knownUsers === null) return <LoadingScreen/>;
    return (
        <Grid container spacing={2}>
            <Grid item xs={8}>
                <h2>{player}</h2>
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    disablePortal
                    options={knownUsers.map(user => ({label: user.name, id: user.id}))}
                    onChange={(e, value) => selectUser(value.id)}
                    renderInput={(params) => <TextField {...params} color={"secondary"} label="User"/>}
                />
            </Grid>
            <Grid item xs={4}>
            </Grid>
            <Grid item xs={12}>
                <Button fullWidth={true} variant={"outlined"} component={Link}
                        to={`/users/${selectedUser}/matches`}>Matches</Button>
            </Grid>
        </Grid>
    );
}

export default User;
